export default {
  animation: {
    description: "Your filters produced no results. Please try again with adjusting or clearing your filters.",
    title: "No matching search results",
  },
  certificateCheck: "Certificate Check",
  certificateHistory: "Certificate History",
  certificateInfo: "Certificate Info",
  certificateStatus: "Certificate Status",
  certificateStatuses: {
    expired: "Expired",
    expiring: "Expiring",
    replaced: "Replaced",
    valid: "Valid",
    withdrawn: "Withdrawn",
  },
  description:
    "The FGW e.V. connects the energy industry and standardizes the technical language of decentralized and renewable energies. In this section, you will find unit- and component- certificates for various voltage levels. Here, you can verify whether these certificates have been reviewed by an accredited certification body and check their current validity status.",
  editParameters: "Edit Parameters",
  editCertificate: "Edit Certificate",
  errorNote: ({ entity, modelName }: { entity: "unit" | "certificate"; modelName: string | undefined }) => {
    let errorNote = `Please describe the issue you noticed within the ${modelName} certificate. Once the issue is resolved by the responsible party, you will be notified by email.`;
    if (entity === "unit")
      errorNote = `Please describe the issue you noticed within the unit ${modelName}. Once the issue is resolved by the responsible party, you will be notified by email.`;
    return errorNote;
  },
  unitEditWarning: {
    info: "You are currently editing the parameters of a unit. Possible changes might be lost. Please leave the edit mode to continue.",
    header: "Plesase leave the edit mode to continue.",
  },
  filterTitles: {
    certificateStatus: "Certificate Status",
    certificateType: "Verification Document",
    manufacturer: "Manufacturer",
    authority: "Certification Body",
    maxActivePower: "Max Active Power",
    norm: "Norm",
    ownCertificates: "Own Certificates",
    primaryEnergy: "Primary Energy",
    ratedPower: "Active Power",
    ratedVoltage: "Rated Voltage",
    subscribed: "Subscribed",
    verified: "Verified",
    isNotReplaced: "Only Current Revisions",
    zerezId: "ZEREZ ID",
  },
  label: "Units and Certificates",
  locked: "Locked",
  mostRecentCertificate: "Most Recent Certificate",
  notVerified: "Not Verified",
  verify: "Verifizieren",
  reportCertificateError: "Report Certificate Error",
  showUnit: "Show Unit",
  since: "since",
  subscribed: "Subscribed",
  subscribeStar: "Subscription",
  subscribe: "Subscribe",
  title: "ZEREZ - Central registry for unit and component certificates",
  unitParameters: {
    electricalParameters: "Electrical Parameters",
    fastVoltageChanges: "Fast Voltage Changes",
    operation1: "Switching Operation 1",
    operation2: "Switching Operation 2",
    operation3: "Switching Operation 3",
    flicker: "Flicker",
    generalInfo: "General Info",
    label: "Unit Parameters",
    powerRanking: "Power Ranking",
  },
  uploadedDocuments: "Uploaded Documents",
  verified: "Verified",
  inCorrection: "In Correction",
  linkToDetailPageOfModel: "Link to Detail Page of Unit",
};
