import { subscript } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  category: "Kategorie/Klasse/Betriebsmittel",
  certificateCode: "ZEREZ ID",
  certificateIssueDate: "Ausgabedatum des Zertifikates",
  certificateNorm: "Norm",
  certificateNumber: "Zertifikatsnummer",
  certificateValidity: "Gültigkeit",
  certificateValidityStatus: "Gültigkeitsstatus",
  certificateType: "Nachweisdokument",
  // certificationBody: "Zertifizierungsstelle",
  certifyingAuthority: "Zertifizierungsstelle",
  // class: "Kategorie/Klasse/Betriebsmittel",
  createdAt: "Hochgeladen am",
  createdBy: "Hochgeladen von",
  documentType: "Nachweisdokument",
  fastVoltageChangeTurnOffAtRatedPower: "Schnelle Spannungsänderung: Ausschalten bei Bemessungsleistung",
  fastVoltageChangeTurnOnAtRatedConditions:
    "Schnelle Spannungsänderung: Einschalten bei Nennbedingungen (des Primärenergieträgers)",
  fastVoltageChangeTurnOnWithoutSpecifications:
    "Schnelle Spannungsänderung: Einschalten ohne Vorgabe (zum Primärenergieträger)",
  fastVoltageChangeWorstCaseDuringGeneratorStageSwitching:
    "Schnelle Spannungsänderung: Ungünstigster Fall beim Umschalten der Generatorstufen",
  importedAt: "Importiert am",
  importedBy: "Importiert von",
  initialShortCircuitAlternatingCurrent: "Anfangs-Kurzschlusswechselstrom",
  kpiTitle: "Anzahl der Zertifikate",
  manufacturer: "Hersteller",
  maxActivePower: "Maximale Wirkleistung",
  maxApparentPower: "Maximale Scheinleistung",
  maxActivePower09: `Maximale Wirkleistung der Erzeugungseinheit bei 0,9 U${subscript("n")}`,
  // Remove the duplicate property
  // name: "Zertifikat",
  // netFeedIn: "Netzeinspeisung",
  modifiedAt: "Letzte Änderung",
  modifiedBy: "Geändert durch",
  primaryEnergy: "Primärenergie",
  primaryEnergySource: "Primärenergiequelle",
  ratedActivePower: "Bemessungswirkleistung",
  ratedApparentPower: "Bemessungsscheinleistung",
  ratedCurrent: "Bemessungsstrom",
  ratedVoltage: "Bemessungsspannung",
  restrictions: "Einschränkungen",
  revision: "Revision",
  replacedByCertificate: "Ersetzt durch Zertifikat",
  replacedCertificate: "Folgt auf Zertifikat",
  shortCircuitPeakCurrent: "Stoßkurzschlussstrom",
  systemFlickerCoefficientCY30: `Flicker, Anlagenflickerbeiwert c${subscript("Ψ")}, 30°`,
  systemFlickerCoefficientCY50: `Flicker, Anlagenflickerbeiwert c${subscript("Ψ")}, 50°`,
  systemFlickerCoefficientCY70: `Flicker, Anlagenflickerbeiwert c${subscript("Ψ")}, 70°`,
  systemFlickerCoefficientCY85: `Flicker, Anlagenflickerbeiwert c${subscript("Ψ")}, 85°`,
  operation1FlickerStepFactor30: `Flickerformfaktor k${subscript("f(Ψ)")}, 30°`,
  operation1FlickerStepFactor50: `Flickerformfaktor k${subscript("f(Ψ)")}, 50°`,
  operation1FlickerStepFactor70: `Flickerformfaktor k${subscript("f(Ψ)")}, 70°`,
  operation1FlickerStepFactor85: `Flickerformfaktor k${subscript("f(Ψ)")}, 85°`,
  operation1MaxNumberN120: `Maximale Anzahl an Schalthandlungen N${subscript("120")}`,
  operation1MaxSwitchN10: `Maximale Anzahl an Schalthandlungen N${subscript("10")}`,
  operation1VoltageVariationFactor30: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 30°`,
  operation1VoltageVariationFactor50: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 50°`,
  operation1VoltageVariationFactor70: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 70°`,
  operation1VoltageVariationFactor85: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 85°`,
  operation2FlickerStepFactor30: `Flickerformfaktor k${subscript("f(Ψ)")}, 30°`,
  operation2FlickerStepFactor50: `Flickerformfaktor k${subscript("f(Ψ)")}, 50°`,
  operation2FlickerStepFactor70: `Flickerformfaktor k${subscript("f(Ψ)")}, 70°`,
  operation2FlickerStepFactor85: `Flickerformfaktor k${subscript("f(Ψ)")}, 85°`,
  operation2MaxNumberN120: `Maximale Anzahl an Schalthandlungen N${subscript("120")}`,
  operation2MaxSwitchN10: `Maximale Anzahl an Schalthandlungen N${subscript("10")}`,
  operation2VoltageVariationFactor30: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 30°`,
  operation2VoltageVariationFactor50: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 50°`,
  operation2VoltageVariationFactor70: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 70°`,
  operation2VoltageVariationFactor85: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 85°`,
  operation3FlickerStepFactor30: `Flickerformfaktork${subscript("f(Ψ)")}, 30°`,
  operation3FlickerStepFactor50: `Flickerformfaktor k${subscript("f(Ψ)")}, 50°`,
  operation3FlickerStepFactor70: `Flickerformfaktor k${subscript("f(Ψ)")}, 70°`,
  operation3FlickerStepFactor85: `Flickerformfaktor k${subscript("f(Ψ)")}, 85°`,
  operation3MaxNumberN120: `Maximale Anzahl an Schalthandlungen N${subscript("120")}`,
  operation3MaxSwitchN10: `Maximale Anzahl an Schalthandlungen N${subscript("10")}`,
  operation3VoltageVariationFactor30: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 30°`,
  operation3VoltageVariationFactor50: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 50°`,
  operation3VoltageVariationFactor70: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 70°`,
  operation3VoltageVariationFactor85: `Spannungsänderungsfaktor k${subscript("u(Ψ)")}, 85°`,
  operationAllWorstCase: `Schlechtester Wert aller Schaltvorgänge k${subscript("i max")}`,
  netFeedIn: "Netzeinspeisung",
};
